<template>
  <section class="">
    <div v-if="isReady">
      <div v-if="hasPermission">
        <component
          v-if="display"
          :is="WIDGET"
          :equipment="equipment"
          :display="display"
          :screenId="selectedScreenId"
          :panelName="panelName"
        />
      </div>
      <InfoBox v-else preset="unauthorized" />
    </div>
    <div class="row" v-else>
      <div class="col-md-6 col-md-offset-3">
        <DashboardNotFound v-if="dashboardError" />
        <div v-else class="overlay overlay-loading">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </div>
    </div>
    <CustomActionManager
      v-if="!hasEventManager"
      v-bind:connector="equipment"
      @openScreen="setScreen"
    />
  </section>
</template>

<script>
import { debounce } from "lodash";
import PageHeader from "@/components/page-header.vue";
import DashboardEquipmentBase from "@/views/private/DashboardEquipmentBase.vue";
import ComponentNotAvailable from "@/components/component-not-available.vue";
import EquipmentDashboardCustom from "@/equipment-dashboard-templates/equipment-dashboard-custom.vue";
import DashboardPrintout from "@/equipment-dashboard-templates/dashboard-printout.vue";
import DashboardContentEditor from "@/equipment-dashboard-templates/dashboard-content-editor.vue";
import DashboardContentViewer from "@/equipment-dashboard-templates/dashboard-content-viewer.vue";

import DashboardNotFound from "@/components/dashboard-not-found.vue";
import CustomActionManager from "@/components/custom-action-manager.vue";
import InfoBox from "@/components/info-box.vue";

import { mqttTopic } from "@/services/equipment.js";

export default {
  name: "DashboardEquipment",
  extends: DashboardEquipmentBase,
  components: {
    PageHeader,
    ComponentNotAvailable,
    EquipmentDashboardCustom,
    DashboardContentEditor,
    DashboardContentViewer,
    DashboardPrintout,
    InfoBox,
    DashboardNotFound,
    CustomActionManager
  },
  computed: {
    userEquipmentDashboardTemplate() {
      let tplName =
        this.$root.config.equipment_dashboard_template ||
        "EquipmentDashboardCustom";
      return tplName;
    },
    WIDGET() {
      if (this.dashboardMode == "editor") {
        return this.$options.components.DashboardContentEditor;
      } else if (this.media == "print") {
        return this.$options.components.DashboardPrintout;
      } else {
        // var components = this.$options.components || {};
        // var tpl =
        //   this.userEquipmentDashboardTemplate || "ComponentNotAvailable";
        // return (tpl in components && components[tpl]) || ComponentNotAvailable;
        return this.$options.components.DashboardContentViewer;
      }
    },
    hasPermission() {
      if (!this.display || !this.templateReady || !this.isReady) return true;
      if (!this.$can("manage", "EquipamentoAcesso")) return false;
      if (this.$route.path.startsWith("/dashboard/screen")) return true;
      return this.$store.getters["user/hasUserAccessTo"](
        (this?.screen() || {})?.process_area?.id
      );
    },
    isMQTT() {
      // return isMQTT(this.equipment); // it seems to be wrongly cached by vue, while under anonymous mode
      return this?.equipment?.protocol?.is_mqtt_protocol || false;
    },
    hasEventManager() {
      // whether there is already action handler instance, do not allow its own
      return this.$route.path.startsWith("/dashboard/search");
    }
  },
  watch: {
    display: {
      handler(n) {
        if (n) {
          this.updateParent();
        }
      },
      deep: true
    }
  },
  methods: {
    updateParent() {
      let tpl = this.equipmentTemplate();
      if (tpl) {
        this.$root.$emit("dashboard:page_layout", {
          page_header: tpl?.page_header ? tpl?.page_header : null,
          page_side_bar: tpl?.page_side_bar ? tpl?.page_side_bar : null
        });
      }
    },
    allowDataMonitor() {
      return (
        this.$store.getters["dashboard/mode"] != "editor" &&
        this.refreshInterval &&
        !this.$route.path.startsWith("/dashboard/screen") &&
        !this.isMQTT
      );
    },
    dataMonitor(option) {
      if (option) {
        if (!this.allowDataMonitor()) {
          // turn off any monitoring eventually already triggered
          this.dataMonitor(false);
          return;
        }
        // regular data pooling
        if (!this._dataRefreshTimer) {
          // begin - test only
          // return; // manual refresh;
          // this.$root.config.dashboard.refreshInterval = 5000;
          // end
          let interval = this.refreshInterval;
          this._dataRefreshTimer = setInterval(
            () => {
              if (this._dataRefreshTimer) {
                this.refreshDashboard();
                // evaluate any interval changes
                if (interval != this.refreshInterval) {
                  this.dataMonitor(false);
                  this.$nextTick(() => {
                    this.dataMonitor(true); // force new config reload
                  });
                }
              }
            },
            interval,
            this
          );
        }
      } else {
        clearInterval(this._dataRefreshTimer);
        this._dataRefreshTimer = null;
      }
    },
    onMQTTStatus(status) {
      if (status == "CONNECTED") {
        this.dataMonitor(false);
        this.$http.options?.mqtt.subscribeOnlyOnTopics(
          Object.keys(this.mqttConnectorTopics || {}).map((t) => `${t}/#`)
        );
      }
    },
    onMQTTMessage($event) {
      if (this.$store.getters["brokerStatus"] !== "CONNECTED") return;
      let entry;
      let topic = $event.info.destinationName.replace(
        /\/(connector_state|alarm_state|completed_data_acquisition|completed_data_write_cycle)/g,
        ""
      );
      let data = (this.dataList || []).find((i) => mqttTopic(i) == topic);
      let connector = this.connectorList.find(
        ({ mqtt_topic_prefix }) => mqtt_topic_prefix == topic
      );
      if (data) {
        // console.log(`${topic} ${JSON.stringify(msg)}`);
        if (/alarm_state/.test($event.info.destinationName)) {
          entry = {
            id: $event.msg.id,
            ...$event.msg
          };
          entry.last_transition_at = new Date(
            $event.msg?.last_transition_at || null
          ).toISOString();
          this.$store.dispatch("dashboard/setAlarmValue", entry);
        } else {
          // console.log(data);
          entry = {
            id: data.id,
            value: $event.msg.value
          };
          if ($event.msg?.timestamp) {
            entry.date_time = new Date($event.msg?.timestamp).toISOString();
            entry.restore = {
              id: data.id,
              date_time: entry.date_time,
              value: entry.value
            };
          }
          this.$store.commit("dashboard/SET_DATA_VALUE", entry);
        }
      } else if (/connector_state/.test($event.info.destinationName)) {
        if (connector) {
          entry = {
            id: connector.id,
            ...$event.msg
          };
          this.$store.dispatch("dashboard/setConnectorValue", entry);
        }
      } else if (
        /completed_data_acquisition/.test($event.info.destinationName)
      ) {
        // it is only valid for modbus connectors
        if (
          this._dataRefreshTimer ||
          !connector ||
          connector?.protocol?.is_mqtt_protocol
        )
          return;
        // it skips connection status - since it will be handle by a different topic
        // console.log("completed_data_acquisition");
        this.onDataArquisition(connector.id);
      } else if (
        /completed_data_write_cycle/.test($event.info.destinationName)
      ) {
        // it is only valid for modbus connectors
        if (
          this._dataRefreshTimer ||
          !connector ||
          connector?.protocol?.is_mqtt_protocol
        )
          return;
        // console.log("completed_data_write_cycle");
        this.$root.$emit("data:sync");
      }
    },
    async onResourcesReady() {
      if (this.mode == "editor" || this._settingUp || this._mqttObserver)
        return;
      this._settingUp = true;
      try {
        this.parseMQTTConnectorTopics();
        if (this.mqttConnectorTopics) {
          this.setupProtocol();
        } else {
          this.dataMonitor(true);
        }
      } catch (error) {
        this.dataMonitor(true);
      }
      this._settingUp = false;
    },
    setupProtocol() {
      if (
        this._mqttObserver ||
        this.dashboardMode == "editor" ||
        this.mode == "editor" ||
        !this.$http.options.mqtt
      )
        return;
      this._mqttObserver = true;
      // this.$root.$on("mqtt:status", this.onMQTTStatus);
      this.onMQTTStatus(this.$http.options.mqtt.status);
      return;
    },
    setScreen({ screenId, connectorId, actionParams }) {
      let url = `/dashboard/equipment/${connectorId || "screen"}/${screenId}`;
      if (url != this.$route.path) {
        if (Object.keys(actionParams || {}).length) {
          localStorage.setItem("_cdim", JSON.stringify(actionParams));
          url = this.$utils.buildUrlSafe(url, { _cdim: "" });
        }
        this.$router.push(url);
        this.$nextTick(() => {
          this.setupDataList();
        });
      }
    },
    parseMQTTConnectorTopics() {
      this.mqttConnectorTopics = null; // non reactive
      if (
        this.dashboardMode == "editor" ||
        this.mode == "editor" ||
        !this.source ||
        !(
          this.$root.$http.options.config?.mqtt?.websocket?.host &&
          this.$root.$http.options.config?.mqtt?.websocket?.port &&
          (this.$root.$http.options.config?.mqtt?.enabled ?? true)
        )
      )
        return;
      let topics = {};
      let instanceIds = [];
      if (this.isMQTTAvailable(this.equipment)) {
        instanceIds.push(parseInt(this?.equipment.id));
      }
      instanceIds = instanceIds.concat(
        Object.values(this?.source?.models || {}).reduce(
          (p, c) => p.concat((c || []).map((id) => parseInt(id))),
          instanceIds
        )
      );

      let dataIds = this?.source?.resourceIds.map(({ id }) => parseInt(id));
      this.dataList.forEach((data) => {
        if (
          dataIds.indexOf(parseInt(data.id)) >= 0 &&
          this.isMQTTAvailable(data.device?.connector) &&
          instanceIds.indexOf(parseInt(data.device?.connector?.id)) == -1
        ) {
          instanceIds.push(parseInt(data.device.connector.id));
        }
      });
      instanceIds = this.$utils.distinct(instanceIds);
      this.connectorList
        .filter(
          ({ id, mqtt_topic_prefix }) =>
            instanceIds.indexOf(parseInt(id)) >= 0 && mqtt_topic_prefix
        )
        .forEach(({ id, mqtt_topic_prefix }) => {
          topics[mqtt_topic_prefix] = id;
        });

      this.mqttConnectorTopics = Object.keys(topics).length ? topics : null;
    },
    isMQTTAvailable(connector) {
      return (
        connector &&
        (connector?.protocol?.is_mqtt_protocol ||
          this?.$root?.config?.mqtt?.modbus_enabled) &&
        connector.mqtt_topic_prefix
      );
    }
  },
  created() {
    this._dataRefreshTimer = null;
    this.$root.editorZoom = 1;
    this.onDataArquisition = debounce((connectorId) => {
      this.refreshDashboard(connectorId, true);
    }, 200);
  },
  beforeCreate() {
    this._onMQTTStatus = ($e) =>
      this.onMQTTStatus && this.onMQTTStatus($e.detail);
    this._onMQTTMessage = ($e) =>
      this.onMQTTMessage && this.onMQTTMessage($e.detail);
    document.addEventListener("mqtt:status", this._onMQTTStatus);
    document.addEventListener("mqtt:message", this._onMQTTMessage);
  },
  beforeDestroy() {
    document.removeEventListener("mqtt:status", this._onMQTTStatus);
    document.removeEventListener("mqtt:message", this._onMQTTMessage);
    this.$store.dispatch("unselectEquipment");
    this.$root.$off("dashboard:enable_pooling", this.dataMonitor);
    this.dataMonitor(false);
  },
  mounted() {
    this.$root.$on("dashboard:enable_pooling", this.dataMonitor);
    if (this.equipment && this.templateReady) this.dataMonitor(true);
  }
};
</script>

<style scoped>
.overlay-loading {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  text-align: center;
  margin-top: 2%;
  color: #607d8b;
  width: 100%;
}
</style>
